import React, { useEffect, useRef, useState } from "react";
import ReactApexChart from "react-apexcharts";
import { colors } from "../../common/Theme/Colors";
import Translate from "../../translation/utils/translateFunction";
import NoDataFound from "../ManageTask/NoDataFound";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { moduleTypes } from "../../common/users/userTypes";
const Columnchart = ({
  optionsList,
  legends,
  selectedFilter,
  seriesData,
  optionsData,
  strokeWidth,
  barWidth,
  graphHeight,
  xValues,
  selectedOptionFilters,
  customFilterSelectedDate,
}) => {
  const [options, setOptions] = useState();
  const [series, setSeries] = useState();
  const { userData } = useSelector((state) => state.formanagement);
  const selectedFiltersRef = useRef(selectedOptionFilters);
  const data = userData?.moduleAccess?.find(
    (item) => Object.keys(item)[0] === moduleTypes.DASHBOARDFILTERS
  );
  useEffect(() => {
    selectedFiltersRef.current = selectedOptionFilters;
  }, [selectedOptionFilters]);

  const navigate = useNavigate();
  useEffect(() => {
    setSeries(seriesData);
    setOptions({
      chart: {
        events: {
          dataPointSelection: (event, chartContext, config) => {
            data?.dashboardFilter?.isEnable &&
              selectedOptionFilters &&
              navigate("reports/task", {
                state: {
                  selectedFilter: {
                    teamsFilter: selectedFiltersRef.current,
                    durationFilter: selectedFilter,
                    ...(customFilterSelectedDate && {
                      customFilterSelectedDate: customFilterSelectedDate,
                    }),
                  },

                  isFromDashboard: true,
                },
              });
          },
        },
        type: "bar",
        id: "basic-bar",
        height: 350,
        toolbar: {
          show: true,
          tools: {
            download: false, // <== line to add
          },
        },
      },
      states: {
        active: {
          filter: { type: "none", value: 0 },
        },
        hover: {
          filter: {
            type: "none",
          },
        },
      },
      plotOptions: {
        bar: {
          columnWidth: barWidth,
          endingShape: "rounded",
          dataLabels: {
            position: "top", // top, center, bottom
          },
        },
      },
      dataLabels: {
        enabled: true,
        offsetY: -20, // Added the offsetY to adjust the label position

        formatter: (val) => {
          return parseFloat(val) + "%";
        },
        style: {
          colors: [colors.grey_004],
        },
        legend: {
          markers: {
            fillColors: legends
              ? Object.values(legends)
              : ["#5a5278", "#6f6593", "#8075aa", "#a192d9"],
          },
        },
        stroke: {
          show: true,
          width: 55,
          colors: ["transparent"],
        },
      },
      offsetX: 2,
      offsetY: -20,
      style: {
        fontSize: "14px",
        colors: [colors.black_002],
      },
      legend: {
        markers: {
          fillColors: legends
            ? Object.values(legends)
            : ["transparent", "transparent"],
        },
        itemMargin: { vertical: 30 },
      },
      stroke: {
        show: true,
        width: strokeWidth,
        colors: ["transparent"],
      },

      xaxis: {
        categories: xValues ? xValues : [""],
        position: "bottom",
        axisBorder: {
          show: false,
        },
        axisTicks: {
          show: false,
        },
        crosshairs: {
          show: false, // <--- HERE
        },
        tooltip: {
          enabled: false,
        },
      },
      yaxis: {
        axisBorder: {
          show: false,
        },
        axisTicks: {
          show: false,
        },
        labels: {
          show: false,
          formatter: (val) => {
            return parseFloat(val).toFixed(2) + "%";
          },
        },
      },
      fill: {
        opacity: 1,
        colors: legends
          ? Object.values(legends)
          : ["transparent", "transparent"],
      },
      tooltip: {
        y: {
          formatter: function (val) {
            return val + " %";
          },
        },
      },

      ...optionsData,
    });
  }, [
    selectedFilter,
    optionsList,
    optionsData,
    seriesData,
    legends,
    strokeWidth,
    barWidth,
    xValues,
    selectedOptionFilters,
  ]);

  return (
    <div>
      {options &&
        series &&
        (optionsData || optionsList?.length ? (
          <div id="chart">
            <ReactApexChart
              options={options}
              series={series}
              type="bar"
              height={graphHeight ? graphHeight : "auto"}
            />
          </div>
        ) : (
          <NoDataFound message={Translate("dashboard:noDataMsg")} isCenter />
        ))}
    </div>
  );
};

export default Columnchart;
