import { Icon, IconButton, Stack } from "@mui/material";
import React, { useEffect, useMemo, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useDebouncedCallback } from "use-debounce";
import { colors } from "../../common/Theme/Colors";
import {
  StyledCard,
  StyledTypography,
} from "../../common/Utils/StyledComponents";
import { showToast } from "../../common/Utils/ToastMessage";
import {
  useGetInprogressTasksMutation,
  useGetTodaysEventsMutation,
  useRiskFactorMutation,
  useTaskProgressFilterMutation,
} from "../../request/Dashboard";
import { useLazyGetMasterDataQuery } from "../../request/MasterValues";
import {
  masterDataAction,
  selectCompanyAction,
  setTaskStatusColorAction,
} from "../../store/Reducer";
import Translate from "../../translation/utils/translateFunction";
import CompanyListing from "../ContactRepository/component";
import ListView from "../ListView";
import { getManagetTaskColumns } from "../ManageTask/column.data";
import DashboardDropDown from "../TextField/DashboardDropdown";
import Columnchart from "../common/columnchart";
import DatePickerModal from "../common/datePickerModal";
import Piechart from "../common/piechart";
import Loader from "../staff/Owner/LoadingComp";
import { getTodaysEventColumns } from "./columns.data";
import { HomeScreenStrings, allFilterData, vendorUser } from "./utils";
// ** Dashboard Related styles
import CompanyHeader from "../../common/CompanyHeader";
import { monthDayYear } from "../../common/Utils/DateFormater";
import {
  getDayJs,
  getFilterNavigate,
  getMoment,
} from "../../common/Utils/commonFunction";
import { SUBTASK_PREFIX } from "../ManageTask/constants";
import "./dashboard.css";
import Lottie from "lottie-react";
import redZoneAnimation from "../../../src/assets/gif/redZone.json";
import { moduleTypes } from "../../common/users/userTypes";

const Dashboard = () => {
  const { isFixNavbar, userData, selectedCompanyList, isRtl } = useSelector(
    (state) => state.formanagement
  );
  const navigate = useNavigate();
  const HomeComponents = {
    GRAPH: 1,
    EVENTS: 2,
    TASKS: 3,
  };
  const barChartOptions = {
    yaxis: {
      max: 110,
      axisBorder: {
        show: false,
      },
      axisTicks: {
        show: false,
      },

      labels: {
        show: false,
        formatter: function (val) {
          if (val === 110) {
            return ""; // hide
          }
          return parseFloat(val).toFixed(2) + "%";
        },
      },
    },
  };

  const [sortBy, setSortBy] = useState(null);
  const [currentPageNo, setCurrentPageNo] = useState(1);
  const [lastPage, setLastPage] = useState(0);
  const [loading, setLoading] = useState(true);
  const [responseFetched, setResponseFetched] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [selectedColumn, setSelectedColumn] = useState("");
  const rowsPerPage = 10;
  const startSerialNumber = (currentPageNo - 1) * rowsPerPage + 1;
  const rowsWithSerialNumbers = responseFetched?.map((row, index) => ({
    ...row,
    serialNumber: startSerialNumber + index,
  }));
  // state for in-progress task list
  const [currentPage, setCurrentPage] = useState(1);
  const [lastPageNo, setLastPageNo] = useState(0);
  const [loadingData, setLoadingData] = useState(true);
  const [responsesFetched, setResponsesFetched] = useState([]);
  const [sortByForTask, setSortByForTask] = useState(null);
  const [selectedColumnForTask, setSelectedColumnForTask] = useState("");
  const rowsSerialNumbers = responsesFetched?.map((row, index) => ({
    ...row,
    serialNumber: startSerialNumber + index,
  }));
  const [chartFilterTaskData, setChartFilterTaskData] = useState();
  const [riskFactorData, setRiskFactorData] = useState();
  const [filterSelectedDate, setFilterSelectedDate] = useState();
  const [value, setValue] = useState(getDayJs(getMoment().format()));
  const [selectedFilterGraph, setSelectedFilterGraph] = useState(
    HomeScreenStrings.MONTH
  );
  const [showDatePickerModal, setShowDatePickerModal] = useState(false);
  const [seriesData, setSeriesData] = useState("");
  const dispatch = useDispatch();
  const [selectedFilters, setSelectedFilters] = useState("SELF");
  const [selectedRiskFilters, setSelectedRiskFilters] = useState("SELF");
  const hideDatePickerModal = () => {
    //setSelectedFilterGraph(HomeScreenStrings.CUSTOM);
    setShowDatePickerModal(false);
  };
  const [taskProgressFilter, setTaskProgressFilter] = useState([]);
  const isDashboardFiltersEnabled = userData?.moduleAccess?.find(
    (item) => Object.keys(item)[0] === moduleTypes.DASHBOARDFILTERS
  );

  const [getMasterData, { data: masterData, isSuccess: isMasterSuccess }] =
    useLazyGetMasterDataQuery();
  const [
    getTaskChartFilter,
    {
      data: taskChartFilterData,
      isLoading: isLoadingTaskChartFilter,
      isSuccess: isSuccessTaskChartFilter,
    },
  ] = useTaskProgressFilterMutation();
  const [
    getRiskFactorFilter,
    {
      data: riskFactorFilterData,
      isLoading: isLoadingRiskFactorFilter,
      isSuccess: isSuccessRiskFactorFilter,
    },
  ] = useRiskFactorMutation();
  const [
    getTodaysEventListApi,
    {
      data: TodaysEventDataList,
      isLoading: isLoadingTodaysEvent,
      error: todaysEventListError,
    },
  ] = useGetTodaysEventsMutation();
  const [
    getInprogessTaskListApi,
    { data: InprogessTaskDataList, isLoading: isLoadingInprogessTask },
  ] = useGetInprogressTasksMutation();

  const requestObjTodaysEvent = useMemo(() => {
    const companyIdArray = [];
    selectedCompanyList?.length > 0 &&
      selectedCompanyList.map((idItem) => companyIdArray.push(idItem.value));
    return companyIdArray?.length > 0
      ? sortBy !== null && sortBy !== undefined
        ? {
          companyId: companyIdArray,
          pageNo: currentPageNo,
          selectedDate: getMoment().format("YYYY-MM-DD"),
          sortCoulmn: selectedColumn,
          sortDirection: sortBy ? 1 : -1,
          loginFor: "WEB",
        }
        : {
          companyId: companyIdArray,
          selectedDate: getMoment().format("YYYY-MM-DD"),
          pageNo: currentPageNo,
          loginFor: "WEB",
        }
      : null;
  }, [currentPageNo, sortBy, selectedCompanyList, selectedColumn]);

  const requestObjInprogressTask = useMemo(() => {
    const companyIdArray = [];
    selectedCompanyList?.length > 0 &&
      selectedCompanyList.map((idItem) => companyIdArray.push(idItem.value));
    return companyIdArray?.length > 0
      ? sortByForTask !== null && sortByForTask !== undefined
        ? {
          companyId: companyIdArray,
          pageNo: currentPage,
          sortCoulmn: selectedColumnForTask,
          sortDirection: sortByForTask ? 1 : -1,
        }
        : {
          companyId: companyIdArray,
          pageNo: currentPage,
        }
      : null;
  }, [currentPage, sortByForTask, selectedCompanyList, selectedColumnForTask]);

  const taskProgressRiskFactorRequest = useMemo(() => {
    if (selectedFilterGraph === HomeScreenStrings.CUSTOM) {
      if (filterSelectedDate) {
        setRiskFactorData();
        setChartFilterTaskData();
      }
    } else {
      setRiskFactorData();
      setChartFilterTaskData();
    }
    return {
      companyId: selectedCompanyList?.length
        ? selectedCompanyList?.map((item) => item?.value)
        : [],
      duration: selectedFilterGraph,
      customDate: filterSelectedDate ? filterSelectedDate : "",
      ...(isDashboardFiltersEnabled?.dashboardFilter?.isEnable && {
        taskFor: selectedRiskFilters,
      }),
    };
  }, [
    selectedCompanyList,
    filterSelectedDate,
    selectedFilterGraph,
    selectedRiskFilters,
  ]);
  const taskProgressRequestPayload = useMemo(() => {
    if (selectedFilterGraph === HomeScreenStrings.CUSTOM) {
      if (filterSelectedDate) {
        setRiskFactorData();
        setChartFilterTaskData();
      }
    } else {
      setRiskFactorData();
      setChartFilterTaskData();
    }
    return {
      companyId: selectedCompanyList?.length
        ? selectedCompanyList?.map((item) => item?.value)
        : [],
      duration: selectedFilterGraph,
      customDate: filterSelectedDate ? filterSelectedDate : "",
      ...(isDashboardFiltersEnabled?.dashboardFilter?.isEnable && {
        taskFor: selectedFilters,
      }),
    };
  }, [
    selectedCompanyList,
    filterSelectedDate,
    selectedFilterGraph,
    selectedFilters,
  ]);

  useEffect(() => {
    getMasterData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    if (selectedCompanyList?.length > 0) {
      if (selectedFilterGraph === HomeScreenStrings.CUSTOM) {
        if (filterSelectedDate) {
          getRiskFactorFilter(taskProgressRiskFactorRequest);
        }
      } else {
        getRiskFactorFilter(taskProgressRiskFactorRequest);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [taskProgressRiskFactorRequest]);
  useEffect(() => {
    if (selectedCompanyList?.length > 0) {
      if (selectedFilterGraph === HomeScreenStrings.CUSTOM) {
        if (filterSelectedDate) {
          getTaskChartFilter(taskProgressRequestPayload);
        }
      } else {
        getTaskChartFilter(taskProgressRequestPayload);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [taskProgressRequestPayload]);

  useEffect(() => {
    if (isSuccessTaskChartFilter && taskChartFilterData?.data?.length) {
      setChartFilterTaskData(taskChartFilterData.data[0]);
    }
    if (isSuccessRiskFactorFilter && riskFactorFilterData?.data?.length) {
      setRiskFactorData(riskFactorFilterData.data[0]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSuccessTaskChartFilter, isSuccessRiskFactorFilter]);

  useEffect(() => {
    if (masterData) {
      const { validationLength, TaskStatusColor } = masterData.data;
      dispatch(masterDataAction(validationLength));
      dispatch(setTaskStatusColorAction(TaskStatusColor));
      const obj = {
        label:
          userData?.companyDetails?.name ||
          userData?.selectedCompanyDetails?.name,
        value:
          userData?.companyDetails?.id || userData?.selectedCompanyDetails?.id,
      };
      !selectedCompanyList?.length && dispatch(selectCompanyAction([obj]));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isMasterSuccess]);

  useEffect(() => {
    if (userData && userData?.masterData) {
      const data = userData?.moduleAccess?.find(
        (item) => Object.keys(item)[0] === moduleTypes.DASHBOARDFILTERS
      );

      data &&
        setTaskProgressFilter(
          Object.values(data?.dashboardFilter?.children)?.filter(
            (item) => item?.isEnable
          )
        );
    }
  }, [userData]);

  useEffect(() => {
    selectedCompanyList?.length > 0 &&
      getTodaysEventListApi(requestObjTodaysEvent);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPageNo, requestObjTodaysEvent]);

  useEffect(() => {
    selectedCompanyList?.length > 0 &&
      getInprogessTaskListApi(requestObjInprogressTask);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage, requestObjInprogressTask]);

  useEffect(() => {
    if (TodaysEventDataList?.data) {
      const { webDashboardEvents = [], pageInfo } = TodaysEventDataList.data;
      setResponseFetched(webDashboardEvents);
      setLastPage(pageInfo?.lastPageNo);
      setLoading(false);
    }
    if (todaysEventListError) {
      if (todaysEventListError?.data?.error) {
        todaysEventListError.data.error.map((errorItem) => {
          return showToast({ message: errorItem?.msg, type: "error" });
        });
      }
      setLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [TodaysEventDataList, todaysEventListError]);

  useEffect(() => {
    if (InprogessTaskDataList?.data) {
      const { nodes, pageInfo } = InprogessTaskDataList.data;
      setResponsesFetched(nodes);
      setLastPageNo(pageInfo?.lastPageNo);
      setLoadingData(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [InprogessTaskDataList]);

  const CustomCellRender = (params) => {
    let type = params?.row?.type;

    switch (type) {
      case "EVENT": {
        return (
          <td
            onClick={() =>
              navigate("/calendar/viewEvent", {
                state: { eventId: params?.row?._id },
              })
            }
          >
            <i
              className="m-2 fa fa-eye cursor-pointer manageIconColor"
              title={Translate("common:viewToolTip")}
            ></i>
          </td>
        );
      }

      case "TASK": {
        return (
          <td
            onClick={() =>
              navigate("/taskDetails", {
                state: { taskId: params?.row?._id, navigateFrom: "/" },
              })
            }
          >
            <i
              className="m-2 fa fa-eye cursor-pointer manageIconColor"
              title={Translate("common:viewToolTip")}
            ></i>
          </td>
        );
      }

      case "SUBTASK": {
        return (
          <td
            onClick={() =>
              navigate("/subtask/view", {
                state: { taskId: params?.row?._id, navigateFrom: "/" },
              })
            }
          >
            <i
              className="m-2 fa fa-eye cursor-pointer manageIconColor"
              title={Translate("common:viewToolTip")}
            ></i>
          </td>
        );
      }

      default:
        break;
    }
  };

  const CustomActionRender = (params) => {
    const { taskNumber } = params?.row;
    const isSubtask = taskNumber?.startsWith(SUBTASK_PREFIX);

    return (
      <td
        onClick={() =>
          navigate(isSubtask ? "/subtask/view" : "/taskDetails", {
            state: {
              taskId: params?.row?._id,
              navigateFrom: "/",
            },
          })
        }
      >
        <i
          className="m-2 fa fa-eye cursor-pointer manageIconColor"
          title={Translate("common:viewToolTip")}
        ></i>
      </td>
    );
  };

  const SubjectCellRender = (params) => {
    return (
      <td>
        <div className="d-flex align-items-center">
          <i
            className="m-2 fa fa-bookmark cursor-pointer"
            style={{ color: params.row.color }}
          ></i>
          <div className="MuiDataGrid-cellContent" role="presentation">
            {params.value}
          </div>
        </div>
      </td>
    );
  };

  const customSrCellRender = (params) => {
    return (
      <StyledTypography component="span" sx={{ color: colors.primary_009 }}>
        {params?.api?.getRowIndexRelativeToVisibleRows(params?.row?._id) +
          1 +
          (currentPage - 1) * 10}
      </StyledTypography>
    );
  };

  const debounced = useDebouncedCallback((columnName) => {
    selectedColumnForTask !== columnName
      ? setSortByForTask(true)
      : setSortByForTask(!sortByForTask);
  }, 500);

  const getSortForTask = (columnName = "") => {
    setLoadingData(true);
    selectedColumnForTask !== columnName && setSortByForTask(undefined);
    debounced(columnName);
    setSelectedColumnForTask(columnName);
  };

  const debouncedForEvent = useDebouncedCallback((columnName) => {
    selectedColumn !== columnName ? setSortBy(true) : setSortBy(!sortBy);
  }, 500);

  const getSortForEvent = (columnName = "") => {
    setLoading(true);
    selectedColumn !== columnName && setSortBy(undefined);
    debouncedForEvent(columnName);
    setSelectedColumn(columnName);
  };

  useEffect(() => {
    if (chartFilterTaskData) {
      setSeriesData(
        chartFilterTaskData.graphData?.map((item) => {
          return {
            name:
              item?.type === "AwaitingApproval"
                ? Translate("performanceReport:resolved")
                : Translate(`performanceReport:${item?.type?.toLowerCase()}`),
            data: [item?.y],
          };
        })
      );
    }
  }, [chartFilterTaskData]);

  const CustomDueDateTodaysCellRender = (params) => {
    const { endDate, endTime } = params?.row;
    return (
      <td>
        <div className="text-info">{monthDayYear(endDate)}</div>
        <div className="text-pink">{endTime}</div>
      </td>
    );
  };
  const CustomStartDateTodaysCellRender = (params) => {
    const { startDate, startTime } = params?.row;
    return (
      <td>
        <div className="text-info">{monthDayYear(startDate)}</div>
        <div className="text-pink">{startTime}</div>
      </td>
    );
  };

  return (
    <>
      <div>
        <div
          className={`section-body ${isRtl ? "rtlMargin" : "ltrMargin"} ${isFixNavbar ? "marginTop" : ""
            } mt-3`}
        >
          <div className="container-fluid">
            <div className="row clearfix">
              <div className="col-lg-12">
                <h4>
                  {Translate("common:welcome")} {userData?.name}
                </h4>
              </div>
            </div>
            {userData?.zone === "RED" && (
              <div className="row clearfix mt-2">
                <div className="col-lg-12">
                  <div className="flexCenter">
                    <Lottie
                      animationData={redZoneAnimation}
                      loop={true}
                      style={{ width: 25, height: 25, marginRight: 10 }}
                    />
                    <h6
                      className="performanceInfoTitle"
                      style={{ color: colors.red }}
                    >
                      {Translate("performanceReport:inRedZone")}
                    </h6>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>

        <div
          className={`section-body dashboard ${isRtl ? "rtlMargin" : "ltrMargin"
            }`}
        >
          <div className="container-fluid">
            <div className="card">
              <Stack
                flexDirection={"row"}
                alignItems={"center"}
                justifyContent={"space-between"}
                px={2}
              >
                <CompanyHeader setShowModal={setShowModal} />
                <Stack
                  className="col-lg-3 col-md-3 col-sm-6 mt-3"
                  flexDirection={"row"}
                  alignItems={"baseline"}
                  justifyContent={"flex-end"}
                >
                  <div className="form-group cursor-pointer">
                    <DashboardDropDown
                      options={allFilterData}
                      className={"cursor-pointer px-1"}
                      placeholder={Translate("taskReport:priorityPlaceHolder")}
                      onSelect={(value) => {
                        if (value === HomeScreenStrings.CUSTOM) {
                          setShowDatePickerModal(true);
                        } else {
                          setSelectedFilterGraph(value);
                          setValue(getDayJs(getMoment().format()));
                          setFilterSelectedDate();
                        }
                      }}
                      value={selectedFilterGraph}
                    />
                  </div>
                </Stack>
              </Stack>
            </div>
            {userData?.dashboardCustomSettings?.SequenceDataOrder.map(
              (order) => {
                return (
                  <Stack>
                    {order === HomeComponents.GRAPH && (
                      <Stack>
                        <div className="row clearfix row-deck">
                          <div className="col-xl-6 col-lg-6 col-md-6">
                            <div className="card">
                              <div
                                className="card-header"
                                style={{
                                  display: "flex",
                                  justifyContent: "space-between",
                                }}
                              >
                                <h3 className="card-title">
                                  {Translate("dashboard:barTitle")}
                                </h3>
                                {!!taskProgressFilter?.length && (
                                  <div className="d-flex ml-2">
                                    <div className="mt-2 headerMargin">
                                      <a
                                        href="/#"
                                        className="nav-link dropdown-toggle "
                                        data-toggle="dropdown"
                                        role="button"
                                        aria-haspopup="true"
                                        aria-expanded="false"
                                      >
                                        <i
                                          className="fa fa-ellipsis-v manageIconColor filterIconSize"
                                          data-toggle="tooltip"
                                          title={Translate("contacts:filter")}
                                        />
                                      </a>
                                      <div className="dropdown-menu dropdownAlignment">
                                        {taskProgressFilter?.map(
                                          (filterItem, index) => (
                                            <>
                                              <div
                                                style={{
                                                  display: "flex",
                                                  padding: "5px",
                                                }}
                                                className={`dropdown-item cursor-pointer ${selectedFilters ===
                                                  filterItem.label.toUpperCase()
                                                  ? "selected unclickable"
                                                  : ""
                                                  }`}
                                                onClick={() => {
                                                  setSelectedFilters(
                                                    filterItem.label.toUpperCase()
                                                  );
                                                }}
                                              >
                                                <span
                                                  className={`manageIconColor ml-10 ${filterItem.icon}`}
                                                  style={{
                                                    padding: "7px",
                                                    marginTop: "1px",
                                                  }}
                                                />

                                                <a style={{ padding: "5px" }}>
                                                  {Translate(
                                                    `common:${filterItem?.label}`
                                                  )}
                                                </a>
                                              </div>
                                              {taskProgressFilter?.length -
                                                1 !==
                                                index && (
                                                  <div className="dropdown-divider" />
                                                )}
                                            </>
                                          )
                                        )}
                                      </div>
                                    </div>
                                  </div>
                                )}
                              </div>
                              <div
                                className="card-body text-center taskProgressBarChart"
                                style={{ height: "600px" }}
                              >
                                {!chartFilterTaskData ? (
                                  <Loader
                                    loading={isLoadingTaskChartFilter}
                                    isListLoader
                                  />
                                ) : (
                                  <Columnchart
                                    optionsList={chartFilterTaskData.graphData}
                                    seriesData={seriesData}
                                    legends={chartFilterTaskData.legendsColor}
                                    setSelectedFilterGraph={
                                      setSelectedFilterGraph
                                    }
                                    selectedFilter={selectedFilterGraph}
                                    strokeWidth={0}
                                    barWidth={"90%"}
                                    optionsData={
                                      chartFilterTaskData.graphData?.length
                                        ? barChartOptions
                                        : null
                                    }
                                    selectedOptionFilters={selectedFilters}
                                    customFilterSelectedDate={
                                      filterSelectedDate
                                    }
                                  />
                                )}
                              </div>
                            </div>
                          </div>
                          <div className="col-xl-6 col-lg-6 col-md-6">
                            <div className="card">
                              <div
                                className="card-header"
                                style={{
                                  display: "flex",
                                  justifyContent: "space-between",
                                }}
                              >
                                <h3 className="card-title">
                                  {Translate("dashboard:donugtTitle")}
                                </h3>
                                {!!taskProgressFilter?.length && (
                                  <div className="d-flex ml-2">
                                    <div className="mt-2 headerMargin">
                                      <a
                                        href="/#"
                                        className="nav-link dropdown-toggle "
                                        data-toggle="dropdown"
                                        role="button"
                                        aria-haspopup="true"
                                        aria-expanded="false"
                                      >
                                        <i
                                          className="fa fa-ellipsis-v manageIconColor filterIconSize"
                                          data-toggle="tooltip"
                                          title={Translate("contacts:filter")}
                                        />
                                      </a>
                                      <div className="dropdown-menu dropdownAlignment">
                                        {taskProgressFilter?.map(
                                          (filterItem, index) => (
                                            <>
                                              <div
                                                style={{
                                                  display: "flex",
                                                  padding: "5px",
                                                }}
                                                className={`dropdown-item cursor-pointer ${selectedRiskFilters ===
                                                  filterItem.label.toUpperCase()
                                                  ? "selected unclickable"
                                                  : ""
                                                  }`}
                                                onClick={() =>
                                                  setSelectedRiskFilters(
                                                    filterItem.label.toUpperCase()
                                                  )
                                                }
                                              >
                                                <span
                                                  className={`manageIconColor ml-10 ${filterItem.icon}`}
                                                  style={{
                                                    padding: "7px",
                                                    marginTop: "1px",
                                                  }}
                                                />

                                                <a style={{ padding: "5px" }}>
                                                  {Translate(
                                                    `common:${filterItem?.label}`
                                                  )}
                                                </a>
                                              </div>
                                              {taskProgressFilter?.length -
                                                1 !==
                                                index && (
                                                  <div className="dropdown-divider" />
                                                )}
                                            </>
                                          )
                                        )}
                                      </div>
                                    </div>
                                  </div>
                                )}
                              </div>
                              <div class="card-body" style={{ padding: '20px 60px' }}>
                                {!riskFactorData ? (
                                  <Loader
                                    loading={isLoadingRiskFactorFilter}
                                    isListLoader
                                  />
                                ) : (
                                  <Piechart
                                    optionsList={riskFactorData.graphData}
                                    legends={riskFactorData.legendsColor}
                                    setSelectedFilterGraph={
                                      setSelectedFilterGraph
                                    }
                                    riskCountList={[
                                      {
                                        type: Translate("dashboard:lowRisk"),
                                        count: riskFactorData?.lowRiskCount,
                                      },
                                      {
                                        type: Translate("dashboard:mediumRisk"),
                                        count: riskFactorData?.mediumRiskCount,
                                      },
                                      {
                                        type: Translate("dashboard:highRisk"),
                                        count: riskFactorData?.highRiskCount,
                                      },
                                    ]}
                                    selectedFilter={selectedFilterGraph}
                                    selectedOptionFilters={selectedRiskFilters}
                                    customFilterSelectedDate={
                                      filterSelectedDate
                                    }
                                  />
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                      </Stack>
                    )}
                    {userData?.role.type === vendorUser ? (
                      <Stack style={{ marginTop: 20 }} />
                    ) : (
                      order === HomeComponents.EVENTS && (
                        <StyledCard variant="outlined">
                          <div className="card">
                            <div className="card-header">
                              <h3 className="card-title">
                                {Translate("dashboard:eventTitle")}
                              </h3>
                            </div>
                            <div class="card-body">
                              <ListView
                                columns={getTodaysEventColumns(
                                  SubjectCellRender,
                                  CustomCellRender,
                                  CustomDueDateTodaysCellRender,
                                  CustomStartDateTodaysCellRender
                                )}
                                rows={rowsWithSerialNumbers}
                                autoHeight
                                sortingMode="server"
                                sortBy={sortBy}
                                onPaginationChange={setCurrentPageNo}
                                currentPage={currentPageNo}
                                showShimmer={isLoadingTodaysEvent}
                                showLoader={loading}
                                totalPageCount={lastPage}
                                noDataMessage={Translate("common:noEventMsg")}
                                onSortModelChange={(data) =>
                                  data?.length &&
                                  getSortForEvent(data[0]?.field)
                                }
                              />
                            </div>
                          </div>
                        </StyledCard>
                      )
                    )}
                    {order === HomeComponents.TASKS && (
                      <StyledCard variant="outlined">
                        <div className="card">
                          {/* <div className="card-header formTitle mx-2">
                  <h3 className="page-title mb-2" style={{ fontSize: "20px" }}> */}
                          <div class="card-header">
                            <h3 class="card-title">
                              {Translate("dashboard:inprogressTitle")}
                            </h3>
                          </div>
                          <div class="card-body">
                            <ListView
                              columns={getManagetTaskColumns(
                                customSrCellRender,
                                CustomActionRender
                              )}
                              rows={rowsSerialNumbers}
                              autoHeight
                              sortingMode="server"
                              sortBy={sortByForTask}
                              onPaginationChange={setCurrentPage}
                              currentPage={currentPage}
                              showShimmer={isLoadingInprogessTask}
                              showLoader={loadingData}
                              totalPageCount={lastPageNo}
                              onSortModelChange={(data) =>
                                data?.length && getSortForTask(data[0]?.field)
                              }
                            />
                          </div>
                        </div>
                      </StyledCard>
                    )}
                  </Stack>
                );
              }
            )}
          </div>
        </div>
      </div>
      <CompanyListing
        handleModal={(value) => {
          setCurrentPageNo(1);
          setCurrentPage(1);
          setShowModal(value);
        }}
        showModal={showModal}
        selectedCompanyList={selectedCompanyList}
      />
      <DatePickerModal
        value={value}
        setValue={setValue}
        closeDatePickerModal={hideDatePickerModal}
        setFilterSelectedDate={setFilterSelectedDate}
        showDatePickerModal={showDatePickerModal}
        handleApply={() => setSelectedFilterGraph(HomeScreenStrings.CUSTOM)}
      />
    </>
  );
};

export default Dashboard;
